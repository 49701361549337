import React from "react";

import Layout from "../components/Layout";
import GdprSection from "../content/GdprSection";

const Gdpr = (props) => {
  return (
    <Layout>
      <GdprSection />
    </Layout>
  );
};

export default Gdpr;
