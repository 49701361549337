import React from "react";
import { ListItem, UnorderedList, Link } from "@chakra-ui/react";

import Paragraph from "../ui/common/Paragraph";
import Section from "../ui/common/Section";

const items = [
  {
    title: "",
    text: (
      <Paragraph>
        <i>Effective Date: 03 November 2019</i>
      </Paragraph>
    ),
  },
  {
    title: "",
    text: (
      <Paragraph>
        The General Data Protection Regulation (GDPR) applies to users of our
        websites or mobile applications who are residents of the European Union
        (EU) or the European Economic Area (EEA) (consisting of the European
        Union, Iceland, Lichtenstein, and Norway). The law sets forth a
        framework for various individual rights on how personal data can be
        used, processed, transmitted, and protected. microPublication is
        committed to take reasonably necessary steps to ensure that your
        personal data is protected consistent with GDPR requirements.
      </Paragraph>
    ),
  },
  {
    title: "Legal Basis",

    text: (
      <>
        <Paragraph>
          Under the GDPR, microPublication is required to have a legal basis for
          collecting personally identifiable information (PII) from residents of
          the EU and the EEA. The legal basis depends on the circumstances in
          which we collect and use your PII and is described more fully in the
          applicable privacy notice. The basis for our processing of your PII
          will fall into one or more of the following categories:
        </Paragraph>

        <UnorderedList>
          <ListItem>
            It is necessary to perform and facilitate contractual duties;
          </ListItem>
          <ListItem>
            It is necessary to protect the interests of the data subject or
            another person;
          </ListItem>
          <ListItem>
            There is a legitimate interest in understanding how our site is
            being used;
          </ListItem>
          <ListItem>
            There is a legitimate interest in carrying out our business
            purposes;
          </ListItem>
          <ListItem>There is a legitimate interest in cybersecurity;</ListItem>
          <ListItem>
            There is a legitimate interest in meeting our obligations and
            enforcing our legal rights; or
          </ListItem>
          <ListItem>You have provided your consent.</ListItem>
        </UnorderedList>
      </>
    ),
  },
  {
    title: "International Transfers",
    text: (
      <Paragraph>
        Data that you provide to us may be transferred to, and stored at, a
        destination outside the EU or the EEA. For instance, this happens when
        it is processed or maintained by staff and/or systems operating in the
        United States. The information that you provide to us is stored on our
        secure servers or those of our service providers. We will take
        reasonably necessary steps to safeguard your data securely.
      </Paragraph>
    ),
  },
  {
    title: "Data Retention",
    text: (
      <Paragraph>
        We will retain your PII for as long as necessary to meet the uses
        described in microPublication’s applicable privacy notice and in
        compliance with business requirements and legal document retention
        obligations. Even where you have exercised one of the rights listed
        below with respect to your personal data, we may have the right to
        retain your personal data for various purposes, including compliance
        with legal obligations, the performance of tasks carried out in the
        public interest, archiving purposes in the public interest, scientific
        or historical research purposes, statistical purposes, or the
        establishment, exercise, or defense of legal claims.
      </Paragraph>
    ),
  },
  {
    title: "Your Rights",
    text: (
      <>
        <Paragraph>
          Website or mobile application users located in the EU or EEA are
          provided with the following rights:
        </Paragraph>
        <UnorderedList>
          <ListItem>
            The right to be informed about the collection and use of your
            personal data;
          </ListItem>
          <ListItem>
            The right to object to the processing of your personal data;
          </ListItem>
          <ListItem>
            The right to rectification of any of your personal data that is
            inaccurate or incomplete;
          </ListItem>
          <ListItem>
            The right to request the deletion of your personal data;
          </ListItem>
          <ListItem>
            The right to restrict or limit the ways in which we process your
            personal data;
          </ListItem>
          <ListItem>
            The right to transfer or obtain a copy of your personal data in an
            easily accessible format;
          </ListItem>
          <ListItem>The right to withdraw consent;</ListItem>
          <ListItem>
            The right to withhold consent to automated individual
            decision-making processes;
          </ListItem>
          <ListItem>The right to complain to a supervisory authority.</ListItem>
        </UnorderedList>
        <Paragraph>
          Please note that the above rights are not absolute. microPublication
          may be entitled to reject requests where certain exceptions apply. To
          submit a request, please contact microPublication’s Privacy Manager,
          Todd Harris, by email at{" "}
          <Link href="mailto:todd.harris@micropublication.org">
            todd.harris@micropublication.org
          </Link>
          .
        </Paragraph>
      </>
    ),
  },
  {
    title: "microPublication Privacy Notices",
    text: (
      <>
        <Paragraph>
          microPublication (via it’s supporting institution, Caltech) has
          privacy notices for specific situations where Caltech processes PII,
          including:
        </Paragraph>
        <UnorderedList>
          <ListItem>Website and Mobile Application Users</ListItem>
          <ListItem>Employees and Job Applicants</ListItem>
          <ListItem>Visitors</ListItem>
          <ListItem>Prospective and Current Students</ListItem>
          <ListItem>Alumni and Donors</ListItem>
          <ListItem>Educational Programs</ListItem>
          <ListItem>Research</ListItem>
        </UnorderedList>

        <Paragraph>
          Copies of Caltech’s privacy notices can be obtained by contacting
          Caltech’s Privacy Manager.
        </Paragraph>
      </>
    ),
  },
  {
    title: "For Additional Information About the GDPR",
    text: (
      <Paragraph>
        If you want more in depth detail about the GDPR, you can read the{" "}
        <Link
          href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CONSIL:ST_5419_2016_INIT&from=EN"
          isExternal
        >
          full text of the EU legislation [PDF]
        </Link>
        .
      </Paragraph>
    ),
  },
  {
    title: "Contact Information",
    text: (
      <>
        <Paragraph>
          If you have questions about microPublication’s policy on
          Confidentiality of Private Information, this or other microPublication
          privacy notices, microPublication’s privacy practices, or any other
          aspect of your privacy and the security of your PII, please contact
          our Privacy Manager at:
        </Paragraph>

        <Paragraph>
          Todd Harris
          <br />
          Technology and Operations Manager
          <br />
          <Link href="mailto:todd.harris@micropublication.org">
            todd.harris@micropublication.org
          </Link>
        </Paragraph>
      </>
    ),
  },
];

const GdprSection = (props) => (
  <Section
    items={items}
    title="General Data Protection Regulation Notice"
    isPage
  />
);

export default GdprSection;
